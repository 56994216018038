import {FtpBean} from "../../model/generated/dtos";


export function newFtpBean(id: number, ftpType: string, host: string, port: number, username: string, password: string, url: string, urlIn: string, ftpOptions: string): FtpBean {
  return {
    id: id,
    ftpType: ftpType,
    host: host,
    port: port,
    username: username,
    password: password,
    url: url,
    urlIn: urlIn,
    ftpOptions: ftpOptions
  }
}
