import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from 'rxjs';
import {FtpBean, FtpOptionsBean} from "../model/generated/dtos";
import {CommonResultBean} from "../model/generated/dtos";
import {ChannelBean} from "../model/generated/dtos";
import {newFtpBean} from "../common/constructors/ftp-bean";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class FtpChannelService {

  checkConnectionChannelFtpUrl: string = environment.BASE_API_URL + 'channels/checkChannelFtpConnection';
  connected: boolean;

  constructor(private http: HttpClient) {}

  isConnected(channelBean: ChannelBean, ftpOptions: FtpOptionsBean[]): Observable<CommonResultBean> {
    console.log("channelBean: ", channelBean);
    console.log("ftpOptions: ", ftpOptions);

    const ftpOptionsString = ftpOptions.filter(ftpOption => ftpOption.checked).map(ftpOption => ftpOption.value).join(" ");

    let ftpBean: FtpBean = newFtpBean(
      channelBean.id,
      channelBean.type,
      channelBean.host,
      channelBean.port,
      channelBean.username,
      channelBean.password,
      channelBean.url,
      channelBean.urlIn,
      ftpOptionsString
    )
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<CommonResultBean>(this.checkConnectionChannelFtpUrl, ftpBean, { headers } );
  }
}
