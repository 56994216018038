<div>
  <h2>{{dictionaryService.dictionary.profileList.header}}</h2>
</div>

<dialog class="mdl-dialog" id="profile-form-dialog" style="width: 512px;">
  <div class="popup-background-blocker"></div>
  <div class="mdl-dialog__content">
    <div class="profile-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text" *ngIf="!currentProfileName">{{dictionaryService.dictionary.profileList.dialog.header}}</h2>
        <h2 class="mdl-card__title-text" *ngIf="currentProfileName">{{currentProfileName}}</h2>
      </div>

      <form #profileForm="ngForm" action="" method="post" class="filters" (ngSubmit)="validateAndSaveForm()">
        <div class="mdl-card__supporting-text "
             style="    margin-left: auto;    margin-right: auto;    margin-top: 20px;">
          <div class=" flex-end mb-10">

            <div class="field-box" style="margin-bottom: 12px;">
              <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.nameInputLabel}}</label></div>
              <div><input class="field " type="text" name="name" style="  width: 100%;" [(ngModel)]="formBean.name"></div>
            </div>
            <!--TODO jbelczacka walidacja-->
            <div class="field-box" style="margin-bottom: 12px;">
              <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.tempPlaylistDaysLabel}}</label></div>
              <div><input class="field" type="number" min="0" name="tempPlaylistDays" #tempPlaylistDays="ngModel" pattern="[0-9]*" style="width: 100%;"
                          [(ngModel)]="formBean.tempPlaylistDays"></div>
              <div style="color: #a94442" *ngIf="!tempPlaylistDays.valid && !tempPlaylistDays.pristine">{{dictionaryService.dictionary.profileList.dialog.tempPlaylistDaysError}}</div>
            </div>

          <div class="field-box" style="margin-bottom: 12px;">
            <p-accordion>
              <p-accordionTab [selected]="false">
                <p-header>
                  {{dictionaryService.dictionary.profileList.dialog.channelsHeader}}
                </p-header>
                  <div class="search-groups--autocomplete disable-all">
                    <p-autoComplete name="channel" (onSelect)="getSelectedChannel($event.value)"
                                    [size]="35"
                                    [placeholder]="dictionaryService.dictionary.profileList.dialog.channelInputPlaceholder" [(ngModel)]="currentChannel"
                                    [suggestions]="foundChannels"
                                    (completeMethod)="searchChannels($event)"
                                    appendTo="body"
                                    field="name">
                      <ng-template let-currentChannel pTemplate="item">
                        <span>{{currentChannel.name}}</span>
                      </ng-template>
                    </p-autoComplete>
                  </div>

                <div class="custom-chips" style="margin-bottom: 12px; display: flex; flex-wrap: wrap">
                  <ng-container *ngFor="let channel of formBean.channels">
                  <span class="mdl-chip mdl-chip--deletable">
                    <span class="mdl-chip__text">{{channel.name}}</span>
                    <button (click)="deleteInput(channel)" type="button" class="mdl-chip__action"><i
                      class="material-icons">close</i></button>
                  </span>
                  </ng-container>
                </div>
              </p-accordionTab>
            </p-accordion>

          </div>

            <div class="field-box" style="margin-bottom: 12px;">
              <div>
                <label class="field-label">{{dictionaryService.dictionary.profileList.dialog.diffReportGenerationLabel}}</label>
              </div>
              <div class="checkbox-single-box">
                <p-checkbox name="diffGeneration" label="{{dictionaryService.dictionary.profileList.dialog.diffReportGenerationOption}}" [(ngModel)]="formBean.diffGeneration"
                            binary="true"></p-checkbox>
              </div>
            </div>


            <div class="field-box" style="margin-bottom: 12px;">
              <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.sendReportViaLabel}}</label></div>
              <div class="checkbox-group-box">
                <p-checkbox name="byFTP" (onChange)="toggleFtpParameters()"
                            label="{{dictionaryService.dictionary.profileList.dialog.sendReportViaOptions.viaPlaylistFtp}}"
                            [(ngModel)]="formBean.byFTP" binary="true" ></p-checkbox>
                <p-checkbox name="byMail" binary="true"
                            label="{{dictionaryService.dictionary.profileList.dialog.sendReportViaOptions.viaEmail}}"
                            [(ngModel)]="formBean.byMail" ></p-checkbox>
                <p-checkbox name="byMaterialFTP"  binary="true"
                            label="{{dictionaryService.dictionary.profileList.dialog.sendReportViaOptions.viaMaterialFtp}}"
                            [(ngModel)]="formBean.byMaterialFTP"></p-checkbox>
              </div>
            </div>
            <div class="field-box">
            <p-accordion>
              <p-accordionTab [selected]="!disabledFTP">
                <p-header>
                  {{dictionaryService.dictionary.profileList.dialog.ftpParametersHeader}}
                </p-header>
                <div class="field-box clearfix">
                  <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.fileProtocolLabel}}</label></div>
                  <div>
                    <div (click)="ftpTypeOpen=!ftpTypeOpen" class="nice-select"
                         [ngClass]="{'open':ftpTypeOpen, 'disabled': disabledFTP}" style=" width: 100%">
                      <span *ngIf="formBean.ftpType" class="current">{{ftpTypeMap.get(formBean.ftpType)}}</span>
                      <span *ngIf="!formBean.ftpType" class="current">{{dictionaryService.dictionary.profileList.dialog.fileProtocolPlaceholder}}</span>
                      <ul class="list">
                        <ng-container>
                          <li class="option" (click)="resetFtpType()">{{dictionaryService.dictionary.profileList.dialog.fileProtocolNone}}</li>
                          <li *ngFor="let ftpTypeKey of ftpTypeKeys" (click)="chooseFtpType(ftpTypeKey);" class="option">
                            {{ftpTypeMap.get(ftpTypeKey)}}
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="field-box">
                  <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.ftpHost}}</label></div>
                  <div><input class="field" [disabled]="disabledFTP" type="text" name="ftpHost" style="  width: 100%;"
                              [(ngModel)]="formBean.ftpHost"></div>
                </div>
                <div class="field-box">
                  <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.ftpPort}}</label></div>
                  <div><input class="field" #ftpPort="ngModel" [disabled]="disabledFTP" type="text" name="ftpPort" style="  width: 100%;"
                              [(ngModel)]="formBean.ftpPort" pattern="[0-9]*"></div>
                  <div style="color: #a94442" *ngIf="!ftpPort.valid && !ftpPort.pristine">{{dictionaryService.dictionary.profileList.dialog.ftpPortError}}</div>
                </div>

                <div class="field-box">
                  <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.ftpUser}}</label></div>
                  <div><input class="field" [disabled]="disabledFTP" type="text" name="ftpUsername" style="  width: 100%;"
                              [(ngModel)]="formBean.ftpUsername"></div>
                </div>

                <div *ngIf="!isKeysAuthentication(formBean.ftpType) && (!isProfileEdit || (isProfileEdit && !isEditedProfileHasByFtp))" class="field-box">
                  <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.ftpPassword}}</label></div>
                  <div><input class="field" [disabled]="disabledFTP" type="password" name="ftpPassword"
                              style="  width: 100%;" [(ngModel)]="formBean.ftpPassword"></div>
                </div>

                <div *ngIf="!isKeysAuthentication(formBean.ftpType)" style="width: 96%" >
                  <p-accordion *ngIf="isProfileEdit && isEditedProfileHasByFtp">
                    <p-accordionTab  [selected]="isPasswordChangeRoller">
                      <p-header (click)="isPasswordChangeRoller=!isPasswordChangeRoller">

                        <span class="profile-change-pass-info">
                            [
                            {{dictionaryService.dictionary.profileList.dialog.ftpPasswordState}}:
                            <span *ngIf="isPasswordChangeReq" style="font-weight: bold; color: red"> {{dictionaryService.dictionary.profileList.dialog.ftpPasswordStateChange}}</span>
                            <span *ngIf="!isPasswordChangeReq" style="font-weight: bold"> {{dictionaryService.dictionary.profileList.dialog.ftpPasswordStateNotChange}}</span>
                            ]
                          </span>
                        {{dictionaryService.dictionary.profileList.dialog.ftpPasswordChange}}

                      </p-header>
                      <div class="field-box">
                        <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.newFtpPassword}}</label></div>
                        <div><input class="field" [disabled]="disabledFTP" type="password" name="ftpPassword" style="  width: 100%;" [(ngModel)]="newPassword" (change)="detectPasswordChange()" (keyup)="detectPasswordChange()"></div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>

                <div class="field-box">
                  <div><label class="field-label">{{dictionaryService.dictionary.profileList.dialog.ftpUrl}}</label></div>
                  <div><input class="field" [disabled]="disabledFTP" type="text" name="ftpUrl" style="  width: 100%;"
                              [(ngModel)]="formBean.ftpUrl"></div>
                </div>

                <div class="field-box"
                     *ngIf="!isASCP(formBean.ftpType) && !!ftpOptionsDict && ftpOptionsDict.length > 0">
                  <p-accordion>
                    <p-accordionTab [selected]="isFtpOptionActive" (click)="isFtpOptionActive=true">
                      <p-header (click)="isFtpOptionActive=false">
                        {{ dictionaryService.dictionary.channelList.dialog.ftpOptions }}
                      </p-header>
                      <div *ngFor="let ftpOption of ftpOptionsDict; let i = index">
                        <div class="checkbox-single-box">
                          <p-checkbox
                            name="{{ftpOption.code}}"
                            label="{{ftpOption.value}}"
                            [(ngModel)]="ftpOptionsDict[i].checked"
                            binary="true">
                          </p-checkbox>
                        </div>
                        <div class="checkbox-single-description-box">
                            <span>
                              <strong>
                                {{ dictionaryService.dictionary.channelList.dialog.ftpOptionsDescription[ftpOption.code] }}
                              </strong>
                            </span>
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>

                <div style="white-space: normal">
                  <p *ngIf="connectionBean.message != null" style="font-size: 13px; padding-left: 2px; padding-top: 10px" [ngClass]="{'error-msg':connectionBean.code!=0}">
                    {{connectionBean.message}}
                  </p>
                  <div class="channel-popup-footer-warning"></div>
                  <button (click)="ftpConnection();"
                          class="channel-popup-footer-button con-btn mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                          type="button" [disabled]="disabledFTP || !isConnectedResponse">
                    {{dictionaryService.dictionary.channelList.dialog.connectButton}}
                  </button>
                </div>

                <div *ngIf="!isConnectedResponse">
                  <loaders-css [loader]="'ball-spin-fade-loader'" [loaderClass]="'ttm-loader'"></loaders-css>
                </div>

              </p-accordionTab>
            </p-accordion>
            </div>




          </div>
        </div>
        <div class="mdl-card__actions mdl-card--border" style="text-align:right;">
          <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  type="submit">
            {{dictionaryService.dictionary.profileList.dialog.saveButton}}
            <i class="material-icons">add</i>
          </button>
        </div>
        <div class="mdl-card__menu">
          <button (click)="closePopup()" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
                  type="button">
            <i class="material-icons" style="color: white;">close</i>
          </button>
        </div>
      </form>

    </div>
  </div>
</dialog>

<dialog class="mdl-dialog" style="max-width: 500px;" id="delete-profile-dialog">
  <div class="popup-background-blocker"></div>
  <div class="popup-content-foregrounder">
    <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.profileList.confirmDialog.header}}</h4>
    <div class="mdl-dialog__content" style="    padding: 20px 24px 24px;">
      <p>
        {{dictionaryService.dictionary.profileList.confirmDialog.question}} {{deleteProfileBean.name | uppercase}}?
      </p>
    </div>
    <div class="mdl-dialog__actions" style="padding: 8px 8px 8px 24px;">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button"
              (click)="closeConfirmPopup('delete-profile-dialog')">
        {{dictionaryService.dictionary.profileList.confirmDialog.cancelButton}}
      </button>
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
              type="submit" (click)="deleteProfile()">
        {{dictionaryService.dictionary.profileList.confirmDialog.confirmButton}}
      </button>
    </div>
  </div>
</dialog>

<form action="" method="post" class="filters" (ngSubmit)="loadSliceProfiles()">
  <div class="custom-panel flex-end mb-20">
    <div class="field-box" >
      <div><label class="field-label">{{dictionaryService.dictionary.profileList.form.header}}:</label></div>
      <div class="search-groups--autocomplete"  >
        <p-autoComplete name="profile"
                        (onSelect)="getSelectedProfile($event.value)"
                        [size]="20"
                        inputStyleClass="profiles-search-name-input"
                        [placeholder]="searchedProfileQuery"
                        [(ngModel)]="searchCurrentProfile"
                        [suggestions]="foundProfiles"
                        (completeMethod)="searchAutocompleteProfiles($event)"
                        appendTo="body"
                        field="name"
                        minLength="0"
                        delay="300" >
          <ng-template *ngFor="let i of foundProfiles" let-currentProfile pTemplate="item">
            <span>{{i.name}}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.profileList.form.channelInputLabel}}:</label></div>
      <div class="search-groups--autocomplete disable-all filter-channel">
        <p-autoComplete name="channel"
                        [size]="23"
                        [placeholder]="dictionaryService.dictionary.profileList.form.channelInputPlaceholder"
                        [(ngModel)]="searchCurrentChannel"
                        [suggestions]="foundFilterChannels"
                        (completeMethod)="searchFilterChannels($event)"
                        appendTo="body"
                        field="name">
          <ng-template let-searchCurrentChannel pTemplate="item">
            <span>{{searchCurrentChannel.name}}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            type="submit" (click)="searchProfiles()" >
      {{dictionaryService.dictionary.profileList.form.filterButton}}
      <i class="material-icons">tune</i>
    </button>
    <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button" (click)="clearFilters()">
      {{dictionaryService.dictionary.profileList.form.clearFilterButton}}
    </button>
    <div class="element-to-right-margin">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
              type="button" (click)="openPopup(); cleanConnectionBean()">
        {{dictionaryService.dictionary.profileList.form.newProfileButton}}
        <i class="material-icons">add</i>
      </button>

    </div>
  </div>
</form>
<div class="profile-table">
  <div class="table-responsive-vertical shadow-z-1">
    <table id="profilesTable" class="table table-hover table-mc-light-blue">
      <thead>
      <tr>

        <!--0 Nazwa profilu-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[0]?.clickable}" style="max-width: 50px" >
          <div  class="column-header-container" (click)="toggleArrow(0, headers[0])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[0]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[0]?.selected" [sortAsc]="headers[0]?.asc"></sort-arrow>
          </div>
        </th>

        <!--1 Przez FTP-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[1]?.clickable}" [style.max-width]="headers[1]?.selected ? '30px' : '30px'" >
          <div  class="column-header-container" (click)="toggleArrow(1, headers[1])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[1]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[1]?.selected" [sortAsc]="headers[1]?.asc"></sort-arrow>
          </div>
        </th>

        <!--2 Przez E-MAIL-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[2]?.clickable}" [style.max-width]="headers[2]?.selected ? '60px' : '50px'">
          <div  class="column-header-container" (click)="toggleArrow(2, headers[2])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[2]?.name}}</div>
            </div>
            <sort-arrow [style.margin-left]="headers[2]?.selected ? '40px' : '0px'" class="column-header-arrow" [selected]="headers[2]?.selected" [sortAsc]="headers[2]?.asc"></sort-arrow>
          </div>
        </th>

        <!--3 Przez FTP materiału-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[3]?.clickable}" [style.max-width]="headers[3]?.selected ? '55px' : '50px'">
          <div  class="column-header-container" (click)="toggleArrow(3, headers[3])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[3]?.name}}</div>
            </div>
            <sort-arrow [style.margin-left]="headers[3]?.selected ? '40px' : '0px'" class="column-header-arrow" [selected]="headers[3]?.selected" [sortAsc]="headers[3]?.asc"></sort-arrow>
          </div>
        </th>

        <!--4 Dostęp do raportu rozbieżności-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[4]?.clickable}" style="width: 95px" >
          <div  class="column-header-container" (click)="toggleArrow(4, headers[4])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[4]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[4]?.selected" [sortAsc]="headers[4]?.asc"></sort-arrow>
          </div>
        </th>

        <!--5 Nazwa hosta FTP-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[5]?.clickable}" [style.max-width]="headers[5]?.selected ? '90px' : '90px'">
          <div  class="column-header-container" (click)="toggleArrow(5, headers[5])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[5]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[5]?.selected" [sortAsc]="headers[5]?.asc"></sort-arrow>
          </div>
        </th>

        <!--6 Numer portu-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[6]?.clickable}" [style.max-width]="headers[6]?.selected ? '55px' : '50px'">
          <div  class="column-header-container" (click)="toggleArrow(6, headers[6])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[6]?.name}}</div>
            </div>
            <sort-arrow [style.margin-left]="headers[6]?.selected ? '40px' : '0px'" class="column-header-arrow" [selected]="headers[6]?.selected" [sortAsc]="headers[6]?.asc"></sort-arrow>
          </div>
        </th>

        <!--7 Protokół pliku-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[7]?.clickable}" style="max-width: 50px;">
          <div  class="column-header-container" (click)="toggleArrow(7, headers[7])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[7]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[7]?.selected" [sortAsc]="headers[7]?.asc"></sort-arrow>
          </div>
        </th>

        <!--8 Adres URL FTP-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[8]?.clickable}" [style.max-width]="headers[8]?.selected ? '70px' : '60px'">
          <div  class="column-header-container" (click)="toggleArrow(8, headers[8])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[8]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[8]?.selected" [sortAsc]="headers[8]?.asc"></sort-arrow>
          </div>
        </th>

        <!--9 Nazwa użytkownika FTP-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[9]?.clickable}" [style.max-width]="headers[9]?.selected ? '75px' : '60px'">
          <div  class="column-header-container" (click)="toggleArrow(9, headers[9])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[9]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" style="margin-left: 70px" [selected]="headers[9]?.selected" [sortAsc]="headers[9]?.asc"></sort-arrow>
          </div>
        </th>

        <!--10 Akcje-->
        <th class="profile-table-cell-padding" [ngClass]="{'column-header-hover': headers[10]?.clickable}" >
          <div  class="column-header-container" (click)="toggleArrow(10, headers[10])" >
            <div class="column-header-inner-container">
              <div class="column-header-content-container">{{headers[10]?.name}}</div>
            </div>
            <sort-arrow class="column-header-arrow" [selected]="headers[10]?.selected" [sortAsc]="headers[10]?.asc"></sort-arrow>
          </div>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let profile of testProfileList">
        <td class="profile-table-cell-padding">{{profile.name}}</td>
        <td>
          <i *ngIf="profile.byFTP!=null && profile.byFTP" class="material-icons yes">check</i>
          <i *ngIf="profile.byFTP==null || !profile.byFTP" class="material-icons">close</i>
        </td>
        <td class="profile-table-cell-padding">
          <!--<span class="mdl-button mdl-js-button mdl-button&#45;&#45;fab mdl-button&#45;&#45;white mdl-button&#45;&#45;icononly mdl-button-static">-->
                <i *ngIf="profile.byMail!=null && profile.byMail" class="material-icons yes">check</i>
          <i *ngIf="profile.byMail==null || !profile.byMail" class="material-icons">close</i>
          <!--</span>-->

        </td>
        <td class="profile-table-cell-padding">
          <i *ngIf="profile.byMaterialFTP!=null && profile.byMaterialFTP" class="material-icons yes">check</i>
          <i *ngIf="profile.byMaterialFTP==null || !profile.byMaterialFTP" class="material-icons">close</i>
        </td>
        <td class="profile-table-cell-padding">
          <i *ngIf="profile.diffGeneration!=null && profile.diffGeneration" class="material-icons yes">check</i>
          <i *ngIf="profile.diffGeneration==null || !profile.diffGeneration" class="material-icons">close</i>
        </td>
        <td class="profile-table-cell-padding">{{profile.ftpHost}}</td>
        <td class="profile-table-cell-padding">{{profile.ftpPort}}</td>
        <td class="profile-table-cell-padding">{{profile.ftpType}}</td>
        <td class="profile-table-cell-padding">{{profile.ftpUrl}}</td>
        <td class="profile-table-cell-padding">{{profile.ftpUsername}}</td>
        <td class="profiles-action-box profile-table-cell-padding">
          <button class="profile-table-button-width mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  type="submit" (click)="openPopup(profile); cleanConnectionBean()">
            {{dictionaryService.dictionary.profileList.table.editButton}}
            <i class="material-icons">edit</i>
          </button>
          <button class="profile-table-button-width mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  (click)="openConfirmDeletePopup(profile)">
            {{dictionaryService.dictionary.profileList.table.deleteButton}}
            <i class="material-icons">delete</i>
          </button>
        </td>
      </tr>
      <tr *ngIf="testProfileList.length==0">
        <td colspan="12" class="no-results-found">{{dictionaryService.dictionary.profileList.table.noResults}} <i class="material-icons">do_not_disturb</i></td>
      </tr>
      </tbody>
    </table>
    <div class="bottom-button-wrapper">
      <button [disabled]="!hasNextPage"
              class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" (click)="nextSlice()"
              type="button">
        {{dictionaryService.dictionary.profileList.table.showMore}}
      </button>
    </div>
  </div>
</div>
<p-blockUI [blocked]="!isConnectedResponse"></p-blockUI>
