<div>
  <h2>{{dictionaryService.dictionary.userList.header}}</h2>

  <dialog class="mdl-dialog" id="user-form-dialog"  style="width: 512px;">
    <div class="popup-background-blocker"></div>
    <div class="mdl-dialog__content">

      <div class="user-card-wide mdl-card mdl-shadow--2dp">
        <div class="mdl-card__title">
          <h2 class="mdl-card__title-text" *ngIf="!currentName">{{dictionaryService.dictionary.userList.dialog.header}}</h2>
          <h2 class="mdl-card__title-text" *ngIf="currentName">{{currentName}}</h2>
        </div>
        <form #userForm="ngForm" action="" method="post" class="filters" (ngSubmit)="saveUser()">
        <div class="mdl-card__supporting-text " style="    margin-left: auto;    margin-right: auto;    margin-top: 20px;">
            <div class=" flex-end mb-20">
              <div *ngIf="user && user.role && (user.role == 'ADMIN')" class="field-box clearfix" style="margin-bottom: 12px">
                <div><label class="field-label">{{dictionaryService.dictionary.userList.dialog.roleInputLabel}}</label></div>
                <p-dropdown
                  [options]="roleForInputInDialog"
                  [(ngModel)]="formBean.role"
                  optionLabel="label"
                  optionValue="value"
                  [style]="{'height':'29px', 'width':'100%'}"
                  [styleClass]="'custom-dropdown'"
                  [placeholder]="dictionaryService.dictionary.userList.dialog.roleInputPlaceholder"
                >
                </p-dropdown>
              </div>

              <div class="field-box" *ngIf="user.role=='MANAGER'" style="margin-bottom: 12px;">
                <div><label class="field-label">{{dictionaryService.dictionary.userList.dialog.profilesHeader}}</label></div>
                <div><span class="user-config-popup-profile-text">{{managerProfileBean.name}}</span></div>
              </div>

              <div class="field-box" style="margin-bottom: 12px;" *ngIf="((formBean.role=='MANAGER' || formBean.role=='USER') && user.role=='ADMIN')">
                <p-accordion>
                  <p-accordionTab [selected]="true">
                    <p-header >
                      {{dictionaryService.dictionary.userList.dialog.profilesHeader}}
                    </p-header>
                    <div class="search-groups--autocomplete disable-all">
                      <p-autoComplete name="profile"
                                      (onSelect)="getSelectedProfile($event.value)"
                                      [size]="70"
                                      [placeholder]="dictionaryService.dictionary.userList.dialog.profilesPlaceholder"
                                      [(ngModel)]="currentProfile"
                                      [suggestions]="foundProfiles"
                                      appendTo="body"
                                      (completeMethod)="searchProfiles($event)"
                                      field="name"
                                      minLength="0"
                                      delay="300" >
                        <ng-template let-currentProfile pTemplate="item">
                          <span>{{currentProfile.name}}</span>
                        </ng-template>
                      </p-autoComplete>
                    </div>

                    <div class="custom-chips" style="margin-bottom: 12px; display: flex; flex-wrap: wrap">
                      <ng-container *ngFor="let profile of formBean.profiles">
                  <span class="mdl-chip mdl-chip--deletable">
                    <span class="mdl-chip__text">{{profile.name}}</span>
                    <button (click)="deleteInput(profile)" type="button" class="mdl-chip__action"><i
                      class="material-icons">close</i></button>
                  </span>
                      </ng-container>
                    </div>
                  </p-accordionTab>
                </p-accordion>

              </div>

              <div class="field-box" style="margin-bottom: 12px;">
                <div><label class="field-label">{{dictionaryService.dictionary.userList.dialog.emailInputLabel}}</label></div>
                <div><input class="field " type="text" name="email" style="  width: 100%;" [(ngModel)]="formBean.email" ></div>
              </div>
              <div class="field-box" style="margin-bottom: 12px;">
                <div><label class="field-label">{{dictionaryService.dictionary.userList.dialog.nameInputLabel}}</label></div>
                <div><input class="field" type="text" name="firstName" style="  width: 100%;" [(ngModel)]="formBean.firstName" ></div>
              </div>
              <div class="field-box test-class" style="margin-bottom: 12px;">
                <div><label class="field-label">{{dictionaryService.dictionary.userList.dialog.surnameInputLabel}}</label></div>
                <div><input class="field" type="text" name="lastName" style="  width: 100%;"  [(ngModel)]="formBean.lastName" ></div>
              </div>
              <div class="field-box test-class" style="margin-bottom: 12px;">
                <div><label class="field-label">{{dictionaryService.dictionary.userList.dialog.phoneInputLabel}}</label></div>
                <div><input class="field" type="text" name="phoneNumber" style="  width: 100%;"  [(ngModel)]="formBean.phoneNumber"  ></div>
              </div>
            </div>
        </div>
        <div class="mdl-card__actions mdl-card--border" style="text-align:right;">
          <button [disabled]="createUserButtonBlocked" class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  type="submit">
            {{dictionaryService.dictionary.userList.dialog.saveButton}}
            <i class="material-icons">add</i>
          </button>
        </div>
        <div class="mdl-card__menu">
          <button (click)="closePopup()" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect" type="button">
            <i class="material-icons" style="color: white;">close</i>
          </button>
        </div>
        </form>
      </div>
    </div>
  </dialog>

  <dialog class="mdl-dialog" style="max-width: 500px;"  id="delete-user-dialog" >
    <div class="popup-background-blocker"></div>
    <div class="popup-content-foregrounder">
      <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.userList.confirmDeleteDialog.header}}</h4>
      <div class="mdl-dialog__content" style="    padding: 20px 24px 24px;">
        <p>
          {{dictionaryService.dictionary.userList.confirmDeleteDialog.question}} {{deleteUserBean.firstName}} {{deleteUserBean.lastName}}?
        </p>
      </div>
      <div class="mdl-dialog__actions" style="padding: 8px 8px 8px 24px;">
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button" (click)="closeConfirmPopup('delete-user-dialog')">
          {{dictionaryService.dictionary.userList.confirmDeleteDialog.cancelButton}}
        </button>
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5" type="submit" (click)="deleteUser()" >
          {{dictionaryService.dictionary.userList.confirmDeleteDialog.confirmButton}}
        </button>
      </div>
    </div>
  </dialog>

  <dialog class="mdl-dialog" style="max-width: 500px;"  id="disable-user-dialog" >
    <div class="popup-background-blocker"></div>
    <div class="popup-content-foregrounder">
      <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.userList.confirmBlockDialog.header}}</h4>
      <div class="mdl-dialog__content" style="    padding: 20px 24px 24px;">
        <p>
          {{dictionaryService.dictionary.userList.confirmBlockDialog.questionFirstPart}}
          {{changeStateUserBean.active? dictionaryService.dictionary.userList.confirmBlockDialog.questionSecondPartBlock : dictionaryService.dictionary.userList.confirmBlockDialog.questionSecondPartUnblock}}
          {{dictionaryService.dictionary.userList.confirmBlockDialog.questionThirdPart}}
          {{changeStateUserBean.firstName}} {{changeStateUserBean.lastName}}?
        </p>
      </div>
      <div class="mdl-dialog__actions" style="padding: 8px 8px 8px 24px;">
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button" (click)="closeConfirmPopup('disable-user-dialog')">
          {{dictionaryService.dictionary.userList.confirmBlockDialog.cancelButton}}
        </button>
        <button [disabled]="popupConfirmButtonBlocked" class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5" type="submit" (click)="changeActivationState()" >
          {{dictionaryService.dictionary.userList.confirmBlockDialog.confirmButton}}
        </button>
      </div>
    </div>
  </dialog>

  <form action="" method="post" class="filters" (ngSubmit)="searchUsers()">
    <div class="custom-panel flex-end mb-20">
      <div class="field-box user-search-input-margin-first">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.emailInputLabel}}:</label></div>
        <div><input class="field p-inputtext" name="email" type="text"  [(ngModel)]="searchRequestBean.email" ></div>
      </div>
      <div class="field-box user-search-input-margin-bottom">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.loginInputLabel}}:</label></div>
        <div><input class="field p-inputtext" type="text" name="login" [(ngModel)]="searchRequestBean.login"  ></div>
      </div>
      <div class="field-box  user-search-input-margin-bottom">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.dateInputLabel}}:</label></div>
        <div class="weekpicker-box">
          <p-calendar class="field"
                      [readonlyInput]="true"
                      dateFormat="yy/mm/dd"
                      selectOtherMonths="true"
                      placeholder="{{dictionaryService.dictionary.userList.form.dateFrom}}"
                      [(ngModel)]="searchRequestBean.searchStartDate"
                      appendTo="body">
          </p-calendar>
          <p-calendar class="field"
                      [readonlyInput]="true"
                      dateFormat="yy/mm/dd"
                      selectOtherMonths="true"
                      placeholder="{{dictionaryService.dictionary.userList.form.dateTo}}"
                      [(ngModel)]="searchRequestBean.searchEndDate"
                      appendTo="body">
          </p-calendar>
        </div>
      </div>
      <div class="field-box  user-search-input-margin-bottom">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.nameInputLabel}}:</label></div>
        <div><input class="field p-inputtext" type="text" name="firstName" [(ngModel)]="searchRequestBean.firstName" ></div>
      </div>
      <div class="field-box user-search-input-margin-bottom">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.surnameInputLabel}}:</label></div>
        <div><input class="field p-inputtext" type="text" name="lastName" [(ngModel)]="searchRequestBean.lastName" ></div>
      </div>
      <div class="field-box user-search-input-margin-bottom" style="margin-bottom: 5px;">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.roleInputLabel}}</label></div>
        <div>
          <p-dropdown
            [options]="roleForInput"
            [(ngModel)]="searchRequestBean.role"
            optionLabel="label"
            optionValue="value"
            [style]="{'height':'29px', 'width':'100%'}"
            [styleClass]="'custom-dropdown'"
            [placeholder]="dictionaryService.dictionary.userList.form.allRoles"
          >
          </p-dropdown>
        </div>
      </div>

      <div class="field-box user-search-input-margin-bottom" *ngIf="user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
        <div><label class="field-label">{{dictionaryService.dictionary.userList.form.profileInputLabel}}</label></div>
        <div class="search-groups--autocomplete"  >
          <p-autoComplete name="profile"
                          (onSelect)="getSelectedProfileSearchBar($event.value)"
                          [size]="20" inputStyleClass="user-profile-search-input"
                          [placeholder]="searchedProfileQuery"
                          [(ngModel)]="searchedProfile"
                          [suggestions]="foundProfiles"
                          (completeMethod)="searchProfilesSearchBar($event)"
                          appendTo="body"
                          field="name"
                          minLength="0"
                          delay="300" >
            <ng-template *ngFor="let i of foundProfiles" let-currentProfile pTemplate="item">
              <span>{{i.name}}</span>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <div class="field-box user-search-input-margin-bottom">
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  user-config-search-pannel-button" type="submit" >
          {{dictionaryService.dictionary.userList.form.filterButton}}
          <i class="material-icons">tune</i>
        </button>
      </div>
      <div class="field-box user-search-input-margin-bottom">
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border  user-config-search-pannel-button" (click)="clearSearch()" type="button">
          {{dictionaryService.dictionary.userList.form.clearFilterButton}}
        </button>
      </div>
      <div class="field-box user-search-new-user-button-margin">
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  user-config-search-pannel-button" type="button" (click)="openPopup()">
          {{dictionaryService.dictionary.userList.form.newUserButton}}
          <i class="material-icons">add</i>
        </button>
      </div>

    </div>
  </form>



  <div>
    <div class="table-responsive-vertical shadow-z-1">
      <table id="table" class="table table-hover table-mc-light-blue">
        <thead>
        <tr>
          <th *ngFor="let header of headers; let i = index" [ngClass]="{'column-header-hover': header.clickable}" class="">
            <div (click)="toggleArrow(i, header)" class="column-header-content">
              {{header.name}}
              <sort-arrow [selected]="header.selected" [sortAsc]="header.asc"></sort-arrow>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="userResponse.content.length <= 0">
          <td colspan="10" class="no-results-found">{{dictionaryService.dictionary.userList.table.noResults}} <i class="material-icons">do_not_disturb</i></td>
        </tr>
        <ng-container *ngIf="userResponse.content.length > 0">
          <tr  *ngFor="let user of userResponse.content">
            <td>{{user.email}}</td>
            <td>{{user.createDate  | date: 'yyyy/MM/dd'}}</td>
            <td>
              {{user.firstName}}
            </td>
            <td>{{user.lastName}}</td>
            <td>{{user.login}}</td>
            <td style="max-width: 165px; word-wrap: break-word;">{{user.phoneNumber}}</td>
            <td>{{mapRole(user.role)}}</td>
            <td>{{user.profileNames ? user.profileNames.toLocaleUpperCase() : ''}}</td>
            <td>{{user.active ? dictionaryService.dictionary.userList.table.active: dictionaryService.dictionary.userList.table.blocked}}</td>
            <td class="user-table-action-button-container">
              <button class="user-table-action-button mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  mb-5" type="submit" (click)="openPopup(user)">
                {{dictionaryService.dictionary.userList.table.editButton}}
              </button>
              <button class="user-table-action-button mdl-button mdl-js-button mdl-button--raised mdl-button-border mb-5" (click)="openConfirmDisablePopup(user)" *ngIf="user.active">
                {{dictionaryService.dictionary.userList.table.blockButton}}
              </button>
              <button class="user-table-action-button mdl-button mdl-js-button mdl-button--raised mdl-button-border mb-5" (click)="openConfirmDisablePopup(user)" *ngIf="!user.active">
                {{dictionaryService.dictionary.userList.table.unblockButton}}
              </button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div class="bottom-button-wrapper">
        <button  class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" [disabled]="!showMore" (click)="nextPage()" type="button">
          {{dictionaryService.dictionary.userList.table.showMore}}
        </button>
      </div>
    </div>
  </div>
</div>
